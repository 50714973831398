import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { ExtraErrorData } from "@sentry/integrations";

if (["production", "staging", "demo"].includes(import.meta.env.MODE)) {
  Sentry.init({
    Vue,
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    // Block common problematic URLs that are not related to us
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
    ],
    // Whitelist of allowed URLs
    allowUrls: [/https?:\/\/((www|img|evients-staging|evients-demo|evients)\.)?(evients\.com|hexation\.com)/],
    // Ignore errors that contain the following strings
    ignoreErrors: ["fb_xd_fragment", "https://googleads.g.doubleclick.net", "pagead/viewthroughconversion"],
    // You may want this to be 100% while in development and sample at a lower rate in production.
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: true,
      }),
      // ExtraErrorData is used to print full data and prevent, for example, [Object object] in the propsData
      new ExtraErrorData({
        depth: 6,
      }),
    ],
  });
}
