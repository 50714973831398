import Vue from "vue";
import VueI18n from "vue-i18n";
import lang_en_GB from "../../lang/en_GB.json";
import lang_en_US from "../../lang/en_US.json";
import lang_it_IT from "../../lang/it_IT.json";
import moment from "moment";
import momentTz from "moment-timezone";
import "moment/dist/locale/it";
import "moment/dist/locale/en-gb";

import "./bootstrap";

Vue.use(VueI18n);
const currentLang = document.documentElement.lang.replace(/-/, "_");

// Global localization for Moment.js
moment.locale(currentLang);
momentTz.locale(currentLang);

// Axios header configs relative to the frontend.
window.axios.defaults.headers.common["Accept-Language"] = currentLang;

export const i18n = new VueI18n({
  fallbackLocale: "en_GB",
  formatFallbackMessages: true,
  locale: currentLang,
  messages: {
    en_GB: lang_en_GB,
    en_US: lang_en_US,
    it_IT: lang_it_IT,
  },
  silentFallbackWarn: true,
  silentTranslationWarn: true,
});
